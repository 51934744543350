.login-form {
  min-width: 100%;
}

.app-logo {
  margin: 20px auto;
  text-align: center;
}

.results-list-item {
  cursor: pointer;
}

.results-list-item:hover {
  opacity: 0.75;
}

@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    float: right !important;
    padding-top: 0px !important;
    overflow: hidden;
    width: auto !important;
  }
}

input {
  font-size: 16px !important;
}

.ant-form-item-label {
  font-weight: 700;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.w-25 {
  width: 100%;
}

@media (min-width: 576px) {
  .w-25 {
    width: 25%;
  }
}

.ct-chart {
  position: relative;
}
.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: left;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  display: inline-block;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  top: 5px;
  content: "";
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend li.inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: relative;
  top: 0;
  right: 0;
}
.ct-legend.ct-legend-inside li {
  display: block;
  margin: 0;
}

.ct-legend .ct-series-0:before {
  background-color: #928d30;
  border-color: #928d30;
}

.ct-legend .ct-series-1:before {
  background-color: #d742d9;
  border-color: #d742d9;
}

.ct-legend .ct-series-2:before {
  background-color: #334e80;
  border-color: #334e80;
}

.ct-legend .ct-series-3:before {
  background-color: #6ddf84;
  border-color: #6ddf84;
}

.ct-legend .ct-series-4:before {
  background-color: #f50d03;
  border-color: #f50d03;
}

.ct-legend .ct-series-5:before {
  background-color: #d8826b;
  border-color: #d8826b;
}

.ct-legend .ct-series-6:before {
  background-color: #0bb679;
  border-color: #0bb679;
}

.ct-legend .ct-series-7:before {
  background-color: #61fd22;
  border-color: #61fd22;
}

.ct-legend .ct-series-8:before {
  background-color: #bb83f2;
  border-color: #bb83f2;
}

.ct-legend .ct-series-9:before {
  background-color: #2dd0e9;
  border-color: #2dd0e9;
}

.ct-legend .ct-series-10:before {
  background-color: #b50a5e;
  border-color: #b50a5e;
}

.ct-legend .ct-series-11:before {
  background-color: #460ee9;
  border-color: #460ee9;
}

.ct-legend .ct-series-12:before {
  background-color: #8ffbeb;
  border-color: #8ffbeb;
}

.ct-legend .ct-series-13:before {
  background-color: #dadd53;
  border-color: #dadd53;
}

.ct-legend .ct-series-14:before {
  background-color: #934f7c;
  border-color: #934f7c;
}

.ct-legend .ct-series-15:before {
  background-color: #6af530;
  border-color: #6af530;
}

.ct-legend .ct-series-16:before {
  background-color: #193b39;
  border-color: #193b39;
}

.ct-legend .ct-series-17:before {
  background-color: #6911b0;
  border-color: #6911b0;
}

.ct-legend .ct-series-18:before {
  background-color: #7ba2f8;
  border-color: #7ba2f8;
}

.ct-legend .ct-series-19:before {
  background-color: #778278;
  border-color: #778278;
}

.ct-legend .ct-series-20:before {
  background-color: #ad9fb3;
  border-color: #ad9fb3;
}

.ct-legend .ct-series-21:before {
  background-color: #8623b2;
  border-color: #8623b2;
}

.ct-legend .ct-series-22:before {
  background-color: #1c730a;
  border-color: #1c730a;
}

.ct-legend .ct-series-23:before {
  background-color: #3b1523;
  border-color: #3b1523;
}

.ct-legend .ct-series-24:before {
  background-color: #1aa7f0;
  border-color: #1aa7f0;
}

.ct-legend .ct-series-25:before {
  background-color: #c5c495;
  border-color: #c5c495;
}

.ct-pie-0 {
  fill: #928d30;
}

.ct-pie-1 {
  fill: #d742d9;
}

.ct-pie-2 {
  fill: #334e80;
}

.ct-pie-3 {
  fill: #6ddf84;
}

.ct-pie-4 {
  fill: #f50d03;
}

.ct-pie-5 {
  fill: #d8826b;
}

.ct-pie-6 {
  fill: #0bb679;
}

.ct-pie-7 {
  fill: #61fd22;
}

.ct-pie-8 {
  fill: #bb83f2;
}

.ct-pie-9 {
  fill: #2dd0e9;
}

.ct-pie-10 {
  fill: #b50a5e;
}

.ct-pie-11 {
  fill: #460ee9;
}

.ct-pie-12 {
  fill: #8ffbeb;
}

.ct-pie-13 {
  fill: #dadd53;
}

.ct-pie-14 {
  fill: #934f7c;
}

.ct-pie-15 {
  fill: #6af530;
}

.ct-pie-16 {
  fill: #193b39;
}

.ct-pie-17 {
  fill: #6911b0;
}

.ct-pie-18 {
  fill: #7ba2f8;
}

.ct-pie-19 {
  fill: #778278;
}

.ct-pie-20 {
  fill: #ad9fb3;
}

.ct-pie-21 {
  fill: #8623b2;
}

.ct-pie-22 {
  fill: #1c730a;
}

.ct-pie-23 {
  fill: #3b1523;
}

.ct-pie-24 {
  fill: #1aa7f0;
}

.ct-pie-25 {
  fill: #c5c495;
}

.ct-legend.custom-legend {
  position: relative;
}

span.ct-label.ct-horizontal.ct-end {
  transform: rotate(-90deg);
  padding-top: 20px;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #f4c63d;
  color: #453d3f;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #f4c63d;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.UsersScreen_StructuresComponent_Flex {
  display: flex;
  justify-content: space-between;
}

.UsersScreen_StructuresComponent_Flex > div {
  width: 100%;
  margin-right: 12px;
}

.UsersScreen_StructuresComponent_Flex > div:last-child {
  margin-right: 0;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-marker {
  color: #fff;
  background: rgb(214, 20, 20);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .UsersScreen_StructuresComponent_Flex {
    flex-direction: column;
  }
}

.totalTurnout {
  stroke: blue;
}

.totalSupporterTurnout {
  stroke: orange;
}

.totalOppositionTurnout {
  stroke: green;
}

svg.ct-chart-bar,
svg.ct-chart-line {
  overflow: visible;
}
.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: left;
  font-size: 10px;
  transform-origin: 100% 1;
  transform: translate(-100%) rotate(-90deg);
  white-space: nowrap;
  fill: #000;
  color: #000;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  fill: #000;
  color: #000;
}

.ant-statistic-title {
  color: #000 !important;
}
